import type { BaseInstance, ModelName } from '@pigello/pigello-matrix';

import { getConfig } from '@/lib/get-config';
import * as api from './api/post';
import { toExternalFieldNames, toInternalFieldNames } from './instanceMapper';

export const createGenericInstance = async <Instance extends BaseInstance>({
  modelName,
  body,
  options,
}: {
  modelName: ModelName;
  body: Partial<Instance>;
  options?: {
    convertRelations?: boolean;
    skipOrganizationOnExternalFieldNames?: string[];
  };
}) => {
  const config = getConfig<ModelName, Instance>(modelName, true);
  if (!config) {
    throw new Error(`No config found for model ${modelName}`);
  }
  const url = config.instanceUrl;
  const bodyToSend = toExternalFieldNames<Instance>(
    config,
    body,
    options?.convertRelations
  ) as Partial<Instance>;

  const res = await api.post<Instance>({
    url,
    body: bodyToSend,
    skipOrganizationOnExternalFieldNames:
      options?.skipOrganizationOnExternalFieldNames,
  });

  return toInternalFieldNames(config, res.data);
};
