import { parseJsonFilter } from '@/hooks/use-table-filter/utils';
import type { BaseInstance } from '@pigello/pigello-matrix';
import type { NextRequest } from 'next/server';
import qs from 'query-string';
import { fetchApi } from '.';
import { BASE_BACKEND_URL } from '../constants';
import { appendOrganiztion } from '../persistantBody';
import type { QueryParams, RequestData } from '../types';
import { handleResponse } from './handle-response';

type Post<T extends BaseInstance> = {
  url: string;
  body: Partial<T> | Partial<T>[];
  queryParams?: QueryParams;
  req?: NextRequest;
  _requestData?: RequestData;
  skipOrganizationOnExternalFieldNames?: string[];
};

export const post = async <T extends BaseInstance>({
  body,
  url,
  _requestData,
  queryParams,
  req,
  skipOrganizationOnExternalFieldNames,
}: Post<T>) => {
  const filters = queryParams?.filters;

  const parsedFilters = parseJsonFilter(filters ?? {});

  const params = qs.stringify(
    { ...queryParams },
    { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' }
  );

  const fullUrl = `${BASE_BACKEND_URL}/${url}${params ? `/?${params}` : '/'}${parsedFilters ? `&${parsedFilters}` : ''}`;

  const requestData: RequestData = _requestData ?? {
    type: 'POST',
    url: fullUrl,
    options: {
      body: JSON.stringify(
        await appendOrganiztion(
          body,
          false,
          skipOrganizationOnExternalFieldNames
        )
      ),
    },
  };

  const response = await fetchApi({
    method: 'POST',
    url: requestData.url,
    body: requestData.options?.body,
    req,
  });

  return (await handleResponse(response, requestData)) as { data: T };
};
